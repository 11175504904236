@import "../../scss/varuables.scss";

.projectsAbout {
	padding: 160px 0 100px;
	background-size: cover;
	position: relative;
	@media (max-width: $md) {
		padding: 60px 0;
	}
	&Img {
		position: absolute;
		top: 100px;
		right: 0;
		height: 500px;
		width: 45%;
		z-index: 2;
		object-fit: cover;
		border-radius: 30px 0 0 30px;
		@media (max-width: $xl) {
			top: 120px;
		}
		@media (max-width: $md) {
			display: none;
		}
	}
	&ImgBG {
		border: 2px solid #1b89ca;
		position: absolute;
		top: 125px;
		right: 30px;
		height: 500px;
		width: 45%;
		z-index: 1;
		object-fit: cover;
		border-radius: 30px 0 0 30px;
		@media (max-width: $xl) {
			top: 145px;
		}
		@media (max-width: $md) {
			display: none;
		}
	}
	.aboutProject {
		width: 100%;
		height: auto;
		padding: 0 0 200px 0;
		@media (min-width: $xxl) {
			padding: 0 0 250px 0;
		}
		@media (max-width: $xl) {
			padding: 0 0 130px 0;
		}
		@media (max-width: $md) {
			padding: 0 0 80px 0;
		}
		@media (max-width: $sm) {
			padding: 0 0 60px 0;
		}
		&Col1 {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 20px;
			width: 63%;
			@media (max-width: $md) {
				width: 90%;
			}
			@media (max-width: $sm) {
				width: 100%;
				text-align: center;
			}
			h2 {
				color: #0364a0;
				@media (max-width: $sm) {
					width: 100%;
					text-align: center;
				}
			}
			p {
				color: #6b6b6b;
				font-size: 23px;
				width: 80%;
				@media (max-width: $md) {
					font-size: 17px;
					width: 100%;
					letter-spacing: 1px;
				}
			}
		}
	}
	.elements {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		justify-content: center;
		gap: 40px;
		@media (max-width: $md) {
			gap: 15px;
			text-align: center;
		}
		h2 {
			color: #0364a0;
			@media (max-width: $sm) {
				width: 100%;
				text-align: center;
			}
		}
		p {
			color: #6b6b6b;
			font-size: 23px;
			width: 80%;
			@media (max-width: $md) {
				font-size: 17px;
				width: 100%;
			}
		}
		&ImgDiv {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 40px;
			width: 100%;
			@media (max-width: $md) {
				gap: 15px;
				flex-wrap: wrap;
			}
			&Hidden {
				background-size: cover;
				border-radius: 14px;
				padding: 30px 40px;
				width: 100%;
				height: 350px;
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
				@media (max-width: $md) {
					height: 250px;
					padding: 20px 10px;
				}
				p {
					color: $light;
					font-size: 18px;
					text-shadow: 1px 1px 5px #000;
					font-size: 30px;
					font-weight: 600;
					@media (max-width: $md) {
						font-size: 19px;
					}
				}
			}
		}
	}
}
