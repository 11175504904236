@import "../../scss/varuables.scss";

.superLocation {
	background-size: cover;
	padding: 6vh 0 15vh;
	position: relative;
	@media (max-width: $sm) {
		padding: 6vh 0 10vh;
	}
	h2 {
		text-align: center;
		margin-bottom: 6vh;
		text-transform: uppercase;
	}
	&__elements {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 50px;
		@media (max-width: $md) {
			flex-wrap: wrap;
		}
		&_col1 {
			width: 50%;
			height: 70vh;
			@media (max-width: $lg) {
				height: 60vh;
			}
			@media (max-width: $md) {
				height: 58vh;
				width: 100%;
			}
			.map {
				position: absolute;
				top: 21vh;
				bottom: 120px;
				left: 0;
				height: 70vh;
				width: 48%;
				border-top: 8px solid $primary;
				border-right: 8px solid $primary;
				border-bottom: 8px solid $primary;
				border-radius: 0 15px 15px 0;
				overflow: hidden;
				@media (max-width: $lg) {
					top: 18vh;
					height: 60vh;
				}
				@media (max-width: $md) {
					width: 100%;
					border-right: none;
					border-radius: 0;
					top: 16vh;
				}
			}
		}
		&_col2 {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			gap: 30px;
			@media (max-width: $md) {
				width: 100%;
			}
			.row2 {
				max-width: 900px;
				width: 100%;
				color: #3a3a3a;
				border-radius: 20px;
				display: flex;
				flex-direction: column;
				gap: 30px;
				@media (max-width: $lg) {
					width: 100%;
					gap: 20px;
				}
				@media (max-width: $sm) {
					width: 100%;
					gap: 10px;
					height: 450px;
				}
				&__ele {
					padding: 0;
					width: 100%;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 30px;
					border-radius: 20px;
					color: $light;
					@media (max-width: $lg) {
						gap: 20px;
					}
					@media (max-width: $sm) {
						flex-wrap: wrap;
						gap: 10px;
						height: 80px;
					}
					h5 {
						font-size: 25px;
						font-weight: normal;
						color: $light;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 10px;
						width: 50%;
						height: 100%;
						@media (max-width: $sm) {
							padding-left: 5px;
							justify-content: flex-start;
							gap: 1px;
							font-size: 19px;
							width: 48%;
						}
						img {
							width: 35px;
							@media (max-width: $sm) {
								width: 30px;
							}
						}
					}
					&_div {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 20px;
						font-size: 20px;
						height: 100%;
						padding: 0 20px;
						border-radius: 20px;
						background: rgba(160, 160, 160, 0.623);
						backdrop-filter: blur(10px);
						@media (max-width: $sm) {
							gap: 10px;
							font-size: 16px;
							padding: 0 20px;
							text-align: center;
							height: 80px;
							margin-top: 90px;
							transform: translateY(-90px);
						}
						div {
							height: 60px;
							width: 60px;
							border-radius: 50%;
							background: $light;
							border: 2px solid #0365a0;
							display: flex;
							align-items: center;
							justify-content: center;
							@media (max-width: $sm) {
								height: 50px;
								width: 50px;
								text-align: center;
							}
							img {
								width: 35px;
								height: auto;
								object-fit: cover;
								@media (max-width: $sm) {
									width: 25px;
								}
							}
						}
						span {
							width: 60%;
						}
					}
				}
			}
		}
	}
}
