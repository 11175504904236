@import "../../scss/varuables.scss";
.mainSymbol {
	border: 2px solid $light;
	border-radius: 13px;
	transform: rotate(45deg);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 22;
	// width: 50px;
	// height: 50px;
	.circle {
		width: 40%;
		height: 40%;
		border-radius: 50%;
		background: $primary;
	}
}
