@import "./varuables.scss";
* {
  padding: 0;
  margin: 0;
  color: inherit;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
a:hover {
  // color: $primary;
}
body {
  overflow-x: hidden;
  margin: 0 auto;
  font-family: sans-serif;
}
h2 {
	font-weight: 600;
	font-size: 60px;
	font-family: sans-serif;
	@media (max-width: $xs) {
		font-weight: 500;
		font-size: 27px;
	}
	@media (min-width: $xs) {
		font-weight: 500;
		font-size: 30px;
	}
	@media (min-width: $sm) {
		font-weight: 500;
		font-size: 35px;
	}
	@media (min-width: $md) {
		font-weight: 500;
		font-size: 40px;
	}
	@media (min-width: $lg) {
		font-weight: 500;
		font-size: 50px;
	}
	@media (min-width: $xl) {
		font-weight: 600;
		font-size: 60px;
	}
	@media (min-width: $xxl) {
		font-weight: 600;
		font-size: 60px;
	}
	@media (min-width: $xxxl) {
		font-weight: 600;
		font-size: 70px;
	}
}
.container {
	width: 100%;
	padding: 0 10%;
	margin: 0 auto;
	@media (max-width: $xs) {
		padding: 0 ;
	}
	@media (min-width: $xs) {
		padding: 0 2%;
	}
	@media (min-width: $sm) {
		padding: 0 2.5%;
	}
	@media (min-width: $md) {
		padding: 0 5%;
	}
	@media (min-width: $lg) {
		padding: 0 10%;
	}
	@media (min-width: $xl) {
		padding: 0 10%;
	}
	@media (min-width: $xxl) {
		padding: 0 15%;
	}
	@media (min-width: $xxxl) {
		padding: 0 20%;
	}

}
.mobileNav {
  display: none;
}
// h1 {
// 	text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.747);
// }
@media (max-width: $xl) {
  .container {
    padding: 0 5%;
    // overflow: hidden;
  }
}
@media (max-width: $md) {
  .mobileNav {
    display: block;
  }
}
