@import "../../scss/varuables.scss";
.footer {
	background-image: linear-gradient(
			to top,
			rgba(102, 102, 102, 0.072),
			rgba(102, 102, 102, 0.103)
		),
		url("../../media/Section.png");
	background-size: cover;
	overflow-x: hidden;
	color: $dark;
	padding: 50px 0;

	&__logo {
		text-align: center;
		@media (max-width: 1360px) {
			width: 150px;
			margin: auto;
		}
	}
	&__line {
		margin: 20px 0;
		width: 100%;
		height: 4px;
		background: $light;
		border-radius: 5px;
	}

	&__row1 {
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		&_col {
			padding: 5px;
			width: 24%;
			height: 240px;
			&:nth-child(2) {
				width: 30%;
				@media (max-width: 1360px) {
					width: 100%;
					height: 300px;
					padding: 0;
				}
			}

			&__title {
				color: $primary;
				margin: 5px 0;
				font-size: 18px;
				font-weight: 700;
				@media screen and (max-width: 650px) {
					font-size: 1.6rem;
					padding: 5px 0;
				}
			}

			a {
				line-height: 2rem;
				color: #000;
				font-size: 1rem;
				display: block;
				width: 80%;
				@media screen and (max-width: 650px) {
					font-size: 1.3rem;
					margin: 10px 0;
				}

				/* For hover effect */

				// background: linear-gradient(
				// 		to right,
				// 		rgba(65, 168, 208, 0.781),
				// 		rgb(202, 255, 255),
				// 		rgb(255, 255, 255)
				// 	),
				// 	linear-gradient(
				// 		to right,
				// 		rgb(0, 123, 255),
				// 		rgb(255, 255, 255),
				// 		rgb(6, 36, 63)
				// 	);
				// background-size: 100% 2px, 0 2px;
				// background-position: 100% 100%, 0 100%;
				// background-repeat: no-repeat;
				// transition: background-size 400ms;
				// border-radius: 10px;
				// &:hover {
				// 	background-size: 0 3px, 100% 3px;
				// }
			}

			@media (max-width: 1360px) {
				width: 100%;
			}
			@media (max-width: 630px) {
				width: 100%;
				height: auto;
			}
		}

		/* responsive */
		@media (max-width: 1360px) {
			display: grid;
			grid-template-columns: repeat(2, 45%);
			justify-content: center;
		}
		@media (max-width: 650px) {
			grid-template-columns: repeat(1, 80%);
			justify-content: center;
			align-items: center;
		}
	}
}
