@import "../../scss/varuables.scss";
.newsCards {
	padding: 120px 0;
	transition: 0.3s;
	&Div {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		gap: 30px 60px;
		overflow: auto;
		@media (max-width: $xl) {
			gap: 10px 30px;
		}
		@media (max-width: $md) {
			gap: 5px 20px;
		}
		.slide {
			width: 40%;
			padding: 0 0 50px 0;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			box-shadow: 0 0 15px #00000058;
			color: $dark;
			transition: 0.3s;
			position: relative;
			margin: 20px 0;
			overflow: hidden;
			@media (max-width: $xl) {
				gap: 15px;
				margin: 10px 0;
				padding: 0 0 40px 0;
				width: 45%;
			}
			@media (max-width: $md) {
				gap: 15px;
				margin: 8px 0;
				padding: 0 0 30px 0;
				width: 45%;
			}
			@media (max-width: $sm) {
				gap: 15px;
				margin: 8px;
				padding: 0 0 30px 0;
				width: 100%;
			}
			&:hover {
				transform: scale(1.01);
			}
			&Img {
				width: 100%;
				height: 350px;
				object-fit: cover;
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				@media (max-width: $md) {
					height: 250px;
				}
			}
			&ImgLine {
				width: 80%;
				margin: 0 auto;
			}
			&Text {
				color: #424242;
				text-align: center;
				font-size: 18px;
				width: 80%;
				margin-left: 10%;
			}
			.titlesDiv {
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 33;
				width: 100%;
				font-family: sans-serif;
				color: $light;
				padding: 20px 20px 40px;
				background: linear-gradient(
					to top,
					#00000000,
					#00000064,
					#0000009f,
					#000000b0
				);
				h5 {
					width: 50%;
					text-align: end;
					font-size: 20px;
				}
				p {
					width: 50%;
					text-align: start;
					font-family: "Marko One", serif;
				}
				@media (max-width: $md) {
					top: 0px;
					left: 0px;
					h5 {
						width: 50%;
					}
					p {
						width: 45%;
						padding-left: 10px;
					}
				}
			}

			.arrowDiv {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				a {
					justify-self: flex-end;
					display: inline-block;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					position: relative;
					&:hover {
						transform: perspective(100px) rotateX(-180deg);
					}
					span {
						color: #000;
						position: absolute;
						top: -8px;
						right: 6px;
						font-size: 3rem;
						font-weight: 100;
					}
				}
			}
		}
	}
}
