@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import "../../scss/varuables.scss";

.projectsHeader {
	width: 100%;
	height: 100vh;
	background-image: url("../../media/u2.png");
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		// margin-top: 20vh;
		text-align: center;
		font-size: 180px;
		font-weight: normal;
		font-family: "Dancing Script", cursive;
		text-shadow: 2px 2px 7px #000;
	}
	&Logo {
		width: 45%;
		backdrop-filter: filter(2px 2px 10px #000);
	}
	&Img {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 30%;
	}
	&Img2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 30%;
		transform: rotate(180deg);
		img {
			width: 100%;
		}
	}
	@media (max-width: $md) {
		height: 70vh;
		&Logo {
			width: 70%;
		}
		&Img {
			width: 70%;
		}
		&Img2 {
			width: 70%;
		}
	}
	@media (max-width: $xs) {
		height: 65vh;
		&Logo {
			width: 60;
		}
	}
}
