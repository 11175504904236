@import "../../scss/varuables.scss";
.projects {
	border-top: 10px solid $primary;
	background-image: url("../../media/u2.png");
	background-size: cover;
	padding: 150px 0 200px 0;
	text-align: center;
	position: relative;
	overflow: hidden;
	@media (max-width: $md) {
		padding: 100px 0 200px 0;
	}
	.container {
		overflow: none;
		@media (max-width: $md) {
			padding: 0 0;
		}
	}
	h2 {
		text-align: center;
		padding: 15px 40px;
		backdrop-filter: blur(10px);
		border-radius: 15px;
		display: inline-block;
		border: 2px solid rgba(255, 255, 255, 0.46);
		position: relative;
		z-index: 12;
	}
	.resourses {
		width: 100%;
		height: 350px;
		margin-top: 70px;
		position: relative;
		z-index: 3;
		@media (max-width: $md) {
			height: 250px;
			margin-top: 70px;
			width: 100%;
		}
		@media (max-width: $sm) {
			height: 200px;
			margin-top: 30px;
		}
		.slide {
			transform: scale(0.8);
			transition: transform 300ms;
			opacity: 0.9;
			position: relative;
			overflow: hidden;
			margin: auto 0;
			@media (max-width: $md) {
				transform: scale(0.95);
			}
			.img {
				border: 2px solid #ffffff89;
				@media (max-width: $md) {
					height: 230px;
				}
				@media (max-width: $sm) {
					height: 160px;
				}
			}
			img {
				width: 100%;
				height: 350px;
				object-fit: cover;
				margin: 0 auto;
				border-radius: 20px;
				filter: blur(1.5px);
				@media (max-width: $md) {
					height: 230px;
				}
				@media (max-width: $sm) {
					height: 160px;
				}
			}
			.link {
				width: 70%;
				text-align: center;
				color: $light;
				position: absolute;
				bottom: -100%;
				left: 15%;
				font-size: 70px;
				transition: 0.6s;
				text-shadow: 2px 2px 7px #000;
				font-family: "Dancing Script", cursive;
				font-weight: 400;
				object-fit: contain;
				opacity: 0;
				&:hover {
					transform: scale(1.07);
				}
			}
		}
		.active {
			transform: scale(1);
			opacity: 1;
			position: relative;
			z-index: 123;
			@media (max-width: $md) {
				height: 100%;
				transform: scale(1.6);
			}
			img {
				width: 100%;
				object-fit: cover;
				filter: blur(0px);
			}
			.link {
				bottom: 0%;
				left: 15%;
				opacity: 1;
			}
		}
		.arrow {
			position: absolute;
			cursor: pointer;
			z-index: 12;
			width: 10%;
			text-align: center;
			transition: 0.1ms;
			color: $light;
			border: 1px solid $light;
			border-radius: 5px;
			width: 40px;
			height: 40px;
			transform: rotate(45deg);
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				color: $light;
				background: $primary;
				transition: 0.1ms;
				border: 1px solid $primary;
			}
			@media (max-width: $md) {
				display: none;
			}
		}
		.next {
			right: -3%;
			top: 48%;
			bottom: auto;
			svg {
				transform: rotate(-45deg);
			}
		}
		.prev {
			left: -3%;
			top: 48%;
			bottom: auto;
			svg {
				transform: rotate(-45deg);
			}
		}
	}
	.logo1 {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 0;
		width: 30%;
		@media (max-width: $md) {
			width: 60%;
		}
	}
	.logo2 {
		position: absolute;
		top: -0.5%;
		right: 0;
		z-index: 0;
		transform: rotate(180deg);
		width: 30%;
		@media (max-width: $md) {
			width: 60%;
		}
		img {
			width: 100%;
		}
	}
	@media (max-width: $md) {
		.slick-list {
			overflow: none !important;
			height: 350px;
			padding: 30px 0;
			.slick-track {
				top: 50px;
			}
		}
	}
}
