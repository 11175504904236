@import "../../scss/varuables.scss";

.forms {
	margin: 120px 0;
	padding: 60px 14%;
	// border-radius: 15px;
	color: $light;
	text-align: center;
	background-image: url("../../media/u13.png");
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;
	@media (max-width: $sm) {
		gap: 20px;
	}
	h3 {
		font-size: 35px;
		font-weight: 500;
		// text-transform: uppercase;
		@media (max-width: $sm) {
			font-size: 25px;
			line-height: 25px;
		}
	}
	p {
		font-size: 23px;
		letter-spacing: 1px;
		width: 70%;
		@media (max-width: $sm) {
			font-size: 17px;
			width: 100%;
		}
	}
	form {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		@media (max-width: $xl) {
			gap: 10px;
		}
		// @media (max-width: $md) {
		// 	gap: 10px;
		// }
		input {
			width: 40%;
			height: 60px;
			padding: 10px 30px;
			border: 3px solid $primary;
			border-radius: 13px;
			background: #0000;
			font-size: 18px;
			color: $light;
			background: none;
			border: 3px solid #ffffff8b;
			backdrop-filter: blur(10px);
			@media (max-width: $md) {
				width: 100%;
			}
			@media (max-width: $sm) {
				height: 50px;
				font-size: 15px;
				border-radius: 10px;
			}
		}
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			color: $light;
			background: #0000;
			border: none;
			font-size: 18px;
			cursor: pointer;
			width: 15%;
			height: 58px;
			@media (max-width: $md) {
				width: 50%;
				margin: 0 auto;
			}
			@media (max-width: $sm) {
				height: 50px;
				font-size: 15px;
				border-radius: 10px;
			}
			span {
				height: 100%;
				width: 100%;
				border-radius: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #ffffff8b;
				backdrop-filter: blur(10px);
			}
		}
	}
}
