@import url("https://fonts.googleapis.com/css2?family=Aclonica&family=Marko+One&family=Playfair+Display:wght@600;700;800&family=Yatra+One&display=swap");
@import "../../scss/varuables.scss";
.news {
	background-image: url("../../media/Section.png");
	border-top: 10px solid $primary;
	border-bottom: 10px solid $primary;
}
.sectionSlides {
	min-height: 10vh;
	place-items: center;
	position: relative;
	padding: 120px 0 170px;
	color: #0364a0;
	text-align: center;
	@media (max-width: $md) {
		padding: 80px 0 100px;
	}
	h2 {
		margin: 0 0 50px 0;
	}
	.slider {
		margin: auto;
		// // position: relative;
		// width: 100%;
		// overflow: hidden;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		// justify-content: center;
		padding: 10px 0;
		.slide_track {
			height: 100%;
			// display: flex;
			// width: calc(500px * 18);
			// animation: scroll 40s linear infinite;
			&:hover {
				animation-play-state: paused;
			}
			@media (max-width: $md) {
				// animation: scroll 60s linear infinite;
				// width: calc(300px * 18);
			}
		}
		.slide {
			margin: 20px;
			width: 500px;
			padding: 10px;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			cursor: pointer;
			&:hover {
				box-shadow: 0 0 15px #00000058;
				transform: scale(1.01);
			}
			@media (max-width: $md) {
				// width: 300px;
				gap: 0.5rem;
				padding: 1px;
			}
			&Img {
				width: 100%;
				height: 350px;
				border-radius: 15px;
				object-fit: cover;
				@media (max-width: $md) {
					height: 250px;
					width: 90%;
					margin: 0 auto;
				}
			}
			&ImgLine {
				width: 80%;
				margin: 0 auto;
			}
			&Text {
				color: #424242;
				text-align: center;
				@media (max-width: $md) {
					font-size: 16px;
					line-height: 18px;
				}
			}
			.titlesDiv {
				display: flex;
				align-items: center;
				justify-content: space-between;
				h5 {
					width: 50%;
					text-align: end;
				}
				p {
					width: auto;
					text-align: center;
					font-family: "Marko One", serif;
				}
			}
			.arrowDiv {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				a {
					justify-self: flex-end;
					display: inline-block;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					position: relative;
					&:hover {
						transform: perspective(100px) rotateX(-180deg);
					}
					span {
						color: #000;
						position: absolute;
						top: -8px;
						right: 6px;
						font-size: 3rem;
						font-weight: 100;
					}
				}
			}
		}
		.shadow {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 12;
			width: 50px;
			height: 100%;
			background: linear-gradient(to right, #fff, #0000);
			@media (max-width: $md) {
				width: 20px;
			}
		}
		.shadow2 {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 12;
			width: 50px;
			height: 100%;
			background: linear-gradient(to left, #fff, #0000);
			@media (max-width: $md) {
				width: 20px;
			}
		}
	}
	.whiteLogo {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 30%;
		z-index: 33;
		@media (max-width: $md) {
			width: 60%;
			height: auto;
		}
	}
	.whiteLogo2 {
		position: absolute;
		right: 0;
		top: 0;
		width: 30%;
		z-index: 33;
		transform: rotate(180deg);
		@media (max-width: $md) {
			width: 60%;
			height: auto;
		}
	}
}
@keyframes scroll {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-300px * 9));
	}
}
