@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../../scss/varuables.scss";
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");

.projectPlan {
	padding: 150px 0;
	text-align: center;
	position: relative;
	@media (max-width: $md) {
		padding: 100px 0 50px 0;
	}
	h2 {
		text-transform: uppercase;
		text-align: center;
		color: #0364a0;
	}
	&__rooms {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 40px;
		color: #4a4a4a;
		margin: 20px 0 0 0;
		@media (max-width: $md) {
			flex-direction: column;
			gap: 10px;
		}
		button {
			padding: 10px 20px;
			text-align: center;
			border-radius: 20px;
			background: none;
			color: #4a4a4a;
			border: 2px solid #00579a;
		}
	}
	.resourses {
		width: 100%;
		height: 450px;
		margin-top: 70px;
		padding-bottom: 50px;
		@media (max-width: $md) {
			margin-top: 0px;
			padding-bottom: 0px;
			height: 370px;
		}
		.slide {
			transform: scale(0.9);
			transition: transform 300ms;
			opacity: 1;
			position: relative;
			text-align: center;
			&:hover {
				transform: scale(1.01);
			}
			@media (max-width: $md) {
				&:hover {
					transform: scale(1.1) translate(0, -30px);
				}
			}
			img {
				width: 100%;
				height: 300px;
				object-fit: contain;
				margin: 0 auto;
				border-radius: 20px;
				transition: 0.3s;
				@media (max-width: $md) {
					height: 200px;
				}
			}
			.text {
				margin-top: 10px;
				text-align: center;
				color: $dark;
				font-size: 15px;
				transition: 0.5s;
				font-weight: 400;
				display: flex;
				align-items: center;
				justify-content: space-around;
				@media (max-width: $md) {
					flex-direction: column;
					gap: 5px;
					font-size: 12px;
					margin-top: -30px;
				}
				p {
					margin-top: 15px;
					color: #4f4f4f;
					font-family: monospace;
					@media (max-width: $md) {
						margin-top: 0px;
					}
					span {
						color: $dark;
						font-weight: 700;
					}
				}
			}
		}
		.active {
			transform: scale(0.9);
			opacity: 1;
			img {
				width: 100%;
				z-index: 123;
				object-fit: contain;
				// filter: blur(0px);
			}
			.link {
				bottom: 30%;
				left: 15%;
			}
		}
		.arrow {
			position: absolute;
			cursor: pointer;
			z-index: 12;
			width: 10%;
			text-align: center;
			transition: 0.1ms;
			color: $primary;
			border: 1px solid $primary;
			border-radius: 5px;
			width: 40px;
			height: 40px;
			transform: rotate(45deg);
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				color: $light;
				background: $primary;
				transition: 0.1ms;
			}
		}
		.next {
			right: 45%;
			bottom: -35%;
			@media (max-width: $md) {
				right: 35%;
				bottom: -25%;
			}
			@media (max-width: $sm) {
				right: 33%;
				bottom: -30%;
			}
			svg {
				transform: rotate(-45deg);
			}
		}
		.prev {
			left: 45%;
			bottom: -35%;
			@media (max-width: $md) {
				left: 35%;
				bottom: -25%;
			}
			@media (max-width: $sm) {
				left: 33%;
				bottom: -30%;
			}
			svg {
				transform: rotate(-45deg);
			}
		}
	}
	.logo1 {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		width: 30%;
	}
	.logo2 {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		transform: rotate(180deg);
		width: 30%;
	}
}
