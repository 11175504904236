@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import "../../scss/varuables.scss";

.newsHeader {
	width: 100%;
	height: 70vh;
	background-image: url("../../media/u2.png");
	background-size: cover;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: $md) {
		height: 50vh;
	}
	h1 {
		// margin-top: 20vh;
		text-align: center;
		font-size: 60px;
		background: #0000;
		backdrop-filter: blur(7px);
		padding: 15px 50px;
		border-radius: 20px;
		border: 2px solid rgba(255, 255, 255, 0.431);
		@media (max-width: $md) {
			font-size: 44px;
		}
		@media (max-width: $sm) {
			font-size: 38px;
		}
		@media (max-width: $xs) {
			font-size: 28px;
		}
	}
	&Img {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 30%;
		@media (max-width: $sm) {
			width: 50%;
		}
	}
	&Img2 {
		position: absolute;
		top: 0;
		right: 0;
		width: 30%;
		transform: rotate(180deg);
		@media (max-width: $sm) {
			width: 50%;
		}
		img {
			width: 100%;
		}
	}
}
