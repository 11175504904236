@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.button {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
	position: relative;
	cursor: pointer;
	border: none;
	border-radius: 50px;
	box-shadow: 5px 7px 25px rgba(0, 0, 0, 0.71);
	&:hover {
		background: $primary;
		color: var(--btnHoverColor);
		transform: scale(0.98);
	}
	&__lightLeft {
		width: 10px;
		height: 10px;
		// border-radius: 50%;
		background: #e1fdff;
		box-shadow: 0 0 50px 20px #e1fdff;
		position: absolute;
		top: 35%;
		left: 20%;
		opacity: 0;
		transition: 0.3s;
	}
	&__lightRight {
		width: 10px;
		height: 10px;
		// border-radius: 50%;
		background: #e1fdff;
		box-shadow: 0 0 50px 20px #e1fdff;
		position: absolute;
		top: 35%;
		right: 20%;
		opacity: 0;
		transition: 0.3s;
	}
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		// color: $light;
		width: 100%;
		height: 100%;
		@media (max-width: $sm) {
			font-size: 15px;
		}
	}
}
.empty {
	width: 100%;
	height: 100%;
	border: 3px solid $primary;
	background: var(--btnEmptyBG);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
	cursor: pointer;
	box-shadow: 5px 7px 25px rgba(0, 0, 0, 0.71);
	&:hover {
		background: $primary;
		color: var(--btnHoverColor);
		transform: scale(0.98);
	}
	.button__lightLeft {
		opacity: 0;
	}
	.button__lightRight {
		opacity: 0;
	}
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		// color: $light;
		width: 100%;
		height: 100%;
		@media (max-width: $sm) {
			font-size: 15px;
		}
	}
}
@media (max-width: $sm) {
	.button {
		padding: 0.7% 2.5%;
		font-size: 0.9rem;
	}
}
@media (max-width: $xs) {
	.button {
		padding: 0.7% 2.5%;
		font-size: 0.9rem;
	}
}
