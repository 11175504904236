@import "../../scss/varuables.scss";
.mobileNavWrap {
	&.active {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20000;
		background: rgba(58, 56, 56, 0.45);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(15.5px);
		-webkit-backdrop-filter: blur(15.5px);
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.18);
	}
	h2 {
		display: block;
	}
	nav {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 300px;
		z-index: 90000;
		position: fixed;
		.menuMain {
			.background {
				background: $primary;
				backdrop-filter: blur(10px);
				border-left: 3px solid rgba(255, 255, 255, 0.577);
			}
		}
	}
	.toggleBG {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: #0000001d;
		z-index: -1;
	}

	.background {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 300px;
		background: var(--background);
	}

	button {
		outline: none;
		border: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 15px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: transparent;
		svg {
			filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.739));
		}
		@media (max-width: $md) {
			top: 12px;
			right: 15px;
		}
	}

	ul,
	li,
	.langs_wrapper {
		margin: 0;
		padding: 0;
	}

	ul {
		padding: 25px;
		position: absolute;
		top: 10px;
		width: 100%;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: center;
	}
	li,
	.langs_wrapper {
		list-style: none;
		margin-bottom: 20px;
		height: 100%;
		cursor: pointer;
	}
	.icon-placeholder {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		flex: 40px 0;
		margin-right: 20px;
	}
	.text-placeholder {
		border-radius: 5px;
		width: 200px;
		height: 20px;
		flex: 1;
	}
	.refresh {
		padding: 10px;
		position: absolute;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 10px;
		width: 20px;
		height: 20px;
		top: 10px;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	a {
		font-family: Inter;
		font-weight: 400;
		font-size: 20px;
		color: $light;
		width: 100%;
		display: block;
		&.active {
			color: $dark;
		}
	}
	.langs_wrapper {
		display: flex;
		gap: 1rem;
		align-items: center;
		height: 100%;
		color: var(--color);
		button {
			position: static;
			width: unset;
			height: unset;
			font-size: 20px;
			&.active {
				color: #319ba1;
			}
		}
	}
}
.logo {
	width: 100%;
	height: 50px;
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin-bottom: 2rem;
	backdrop-filter: drop-shadow(4px 4px 10px rgb(0, 0, 0));
	&__img {
		width: auto;
		height: 100%;
		backdrop-filter: drop-shadow(4px 4px 10px rgb(0, 0, 0));
	}
}
