@import "../../scss/varuables.scss";

.advantages {
	background-image: url("../../media/Section.png");
	background-size: cover;
	color: #424242;
	padding: 100px 150px;
	@media (max-width: $lg) {
		padding: 100px 100px;
	}
	@media (max-width: $md) {
		padding: 100px 0px 70px;
	}
	h2 {
		margin-bottom: 50px;
		@media (max-width: $md) {
			margin-bottom: 30px;
		}
	}
	&__elements {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		// gap: 50px;
		&_texts {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 20px;
			padding-bottom: 50px;
			position: relative;
			@media (max-width: $md) {
				padding-bottom: 30px;
			}
			@media (max-width: $sm) {
				padding-bottom: 20px;
			}
			.line {
				border-right: 2px solid #b8b8c1;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 25px;
				@media (max-width: $md) {
					left: 20px;
				}
				@media (max-width: $md) {
					left: 17px;
				}
			}
			h3 {
				display: flex;
				align-items: center;
				gap: 25px;
				font-weight: 700;
				font-size: 23px;
				@media (max-width: $md) {
					gap: 15px;
					font-size: 20px;
				}
				@media (max-width: $sm) {
					gap: 7px;
					align-items: flex-start;
				}
				span {
					position: relative;
					z-index: 2;
					font-weight: 500;
					width: 50px;
					height: 50px;
					color: $light;
					border-radius: 50%;
					background: $primary;
					display: flex;
					align-items: center;
					justify-content: center;
					@media (max-width: $md) {
						width: 40px;
						height: 40px;
					}
					@media (max-width: $sm) {
						width: 34px;
						height: 34px;
					}
				}
			}
			p {
				margin-left: 80px;
				font-size: 20px;
				line-height: 32px;
				font-weight: 600;
				@media (max-width: $md) {
					margin-left: 60px;
					font-size: 17px;
					line-height: 25px;
				}
				@media (max-width: $sm) {
					margin-left: 30px;
					font-size: 17px;
					line-height: 22px;
				}
			}
			.videoTexts {
				margin: 40px 0 40px 80px;
				gap: 40px;
				width: 100%;
				height: 600px;
				@media (max-width: $md) {
					margin: 30px 0 30px 60px;
					width: 83%;
					height: 300px;
				}
				@media (max-width: $sm) {
					margin: 20px 0 20px 30px;
					width: 85%;
					height: 200px;
				}
				h3 {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					p {
						margin: 0;
					}
				}
			}
		}
	}
}
