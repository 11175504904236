@import "../../scss/varuables.scss";
.header {
	position: relative;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	&__container {
		width: 100%;
		height: 100%;
		&_elements {
			position: absolute;
			top: 25vh;
			left: 15%;
			z-index: 12;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 20px;
			width: 70%;
			text-align: center;
			@media (min-width: $xxl) {
				top: 30vh;
			}
			h1 {
				font-size: 170px;
				font-family: $font_titles;
				font-weight: 700;
				// line-height: 40px;
				text-align: center;
				text-shadow: 2px 2px 10px #00000088;
				@media (min-width: $xxl) {
					font-size: 200px;
				}
			}
			p {
				text-align: center;
				font-size: 50px;
				font-family: $font_texts;
				width: 65%;
				text-transform: uppercase;
				font-weight: 600;
				text-shadow: 2px 2px 10px #00000088;
			}
			&_button {
				color: $dark;
				position: absolute;
				bottom: 10vh;
				left: 50vw;
				z-index: 3;
				width: 165px;
				height: 60px;
				border-radius: 50px;
				transform: translateX(-50%);
			}
			&_whiteLogo {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 2;
				width: 30%;
			}
			&_whiteLogo2 {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 2;
				transform: rotate(180deg);
				width: 30%;
				img {
					width: 100%;
				}
			}
		}
		&_img {
			width: 100%;
			height: 100%;
			max-height: 100%;
			position: absolute;
			right: 0%;
			top: 0%;
			z-index: 1;
			object-fit: cover;
			// transform: rotate(-100deg);
			// animation: car 2s cubic-bezier(0.87, -0.17, 0.16, 1.27) 1 alternate
			// 	forwards;
			// animation-timing-function: cubic-bezier(0.94, -0.24, 0.47, 1.07);
		}
		&_img2 {
			width: 100%;
			height: 100%;
			max-height: 100%;
			position: absolute;
			right: 0%;
			top: 0%;
			z-index: 0;
			object-fit: cover;
			// transform: rotate(-100deg);
			// animation: car 2s cubic-bezier(0.87, -0.17, 0.16, 1.27) 1 alternate
			// 	forwards;
			// animation-timing-function: cubic-bezier(0.94, -0.24, 0.47, 1.07);
		}
		&_imgMobile {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			object-fit: cover;
		}
	}

	@media (min-width: $xxl) {
		height: 90vh;
	}

	@media (min-width: 2410px) {
		height: 100vh;
	}
}
@media (max-width: $xl) {
	.header {
		height: 80vh;
		&__container {
			&_elements {
				position: absolute;
				top: 30%;
				left: 0;
				z-index: 12;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				gap: 30px;
				width: 100%;

				h1 {
					font-size: 100px;
					font-family: $font_titles;
					font-weight: 700;
					line-height: 40px;
					margin-bottom: 20px;

					span {
						font-weight: 400;
					}
				}
				p {
					margin-bottom: 10px;
					font-size: 40px;
					line-height: 40px;
					font-family: $font_texts;
				}
			}
		}
	}
}
@media (max-width: $md) {
	.header {
		height: 80vh;
		&__container {
			&_elements {
				h1 {
					font-size: 70px;
				}
				p {
					font-size: 35px;
				}
			}
		}
		&__categories {
			bottom: -90px;
		}
		.arrows {
			width: 60%;
			bottom: 130px;
			left: 20%;
		}
	}
}
@media (max-width: $sm) {
	.header {
		height: 70vh;
		&__container {
			&_elements {
				gap: 0px;
				h1 {
					font-size: 50px;
				}
				p {
					font-size: 20px;
					line-height: 30px;
				}
				&_button {
					color: $dark;
					position: absolute;
					bottom: 10vh;
					left: 50vw;
					z-index: 3;
					width: 140px;
					height: 50px;
					
				}
				&_whiteLogo {
					width: 60%;
				}
				&_whiteLogo2 {
					width: 60%;
				}
			}
			&_imgMobile {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1234;
				bottom: 0%;
				width: 100%;
				height: 100%;
				object-fit: cover;
				opacity: 1;
			}
		}
	}
}
