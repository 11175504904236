@import "../../scss/varuables.scss";

.aboutUsDiv {
	background: var(--background);
	position: relative;
	background-image: url("../../media/u6.png");
	background-size: cover;
	border-top: 10px solid $primary;
	border-bottom: 10px solid $primary;
	width: 100%;
	overflow: hidden;
	padding: 150px 0;
	.aboutUs {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__col1 {
			width: 60%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 50px;
			position: relative;
			z-index: 2;
			@media (max-width: $md) {
				gap: 20px 15px;
				width: 160%;
			}
			@media (max-width: $sm) {
				gap: 10px 15px;
				width: 160%;
			}
			&_titles {
				@media (max-width: $md) {
					position: relative;
					height: 40px;
					width: 100%;
				}
				h2 {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 20px;
					text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.538);
					@media (min-width: $xxl) {
						line-height: 30px;
					}
					@media (max-width: $md) {
						position: absolute;
						top: 10%;
						left: 0;
					}
				}
			}
			&_texts {
				p {
					font-size: 23px;
					text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.538);
					line-height: 1.5;
					@media (max-width: $xl) {
						line-height: 1.2;
						width: 120%;
					}
					@media (max-width: $lg) {
						width: 150%;
					}
					@media (max-width: $md) {
						font-size: 17px;
						width: 120%;
					}
					@media (max-width: $sm) {
						font-size: 16px;
						width: 120%;
						margin: 15px 0;
					}
				}
			}
			.counts {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 100px 80px;
				width: 100%;
				@media (max-width: $xl) {
					gap: 40px 30px;
				}
				@media (max-width: $lg) {
					gap: 20px 40px;
					width: 150%;
				}
				@media (max-width: $md) {
					gap: 0px 20px;
					width: 125%;
				}
				@media (max-width: $sm) {
					gap: 0px 10px;
					width: 125%;
				}
				h4 {
					font-size: 45px;
					font-weight: normal;
					line-height: 50px;
					border: 2px solid rgba(255, 255, 255, 0.46);
					width: 45%;
					padding: 1% 5%;
					border-radius: 15px;
					backdrop-filter: blur(10px);

					@media (max-width: $xl) {
						width: 47%;
					}
					@media (max-width: $lg) {
						width: 80%;
					}
					@media (max-width: $md) {
						width: 80%;
						font-size: 35px;
						line-height: 35px;
						padding: 2% 5%;
					}
					@media (max-width: $sm) {
						width: 80%;
						font-size: 25px;
						line-height: 25px;
						padding: 2% 3%;
						overflow: hidden;
					}
					.counts_span {
						display: flex;
						align-items: center;
						gap: 15px;
						font-size: 15px;
						font-weight: normal;
						@media (max-width: $sm) {
							width: 120%;
							font-size: 11px;
							gap: 5px;
							text-align: start;
							line-height: 15px;
							margin: 0 auto;
						}
					}
					i {
						font-size: 16px;
					}
				}
			}
		}
		&__col2 {
			width: 40%;
			&_img1 {
				width: 40%;
				height: 80%;
				position: absolute;
				bottom: 0;
				right: 0;
				object-fit: cover;
				z-index: 1;
				@media (max-width: $xl) {
					width: 50%;
					height: 70%;
				}
				@media (max-width: $md) {
					display: none;
				}
			}
			&_img2 {
				width: 35%;
				position: absolute;
				bottom: 0;
				left: 0;
				@media (max-width: $xl) {
					width: 45%;
				}
				@media (max-width: $md) {
					width: 55%;
				}
				@media (max-width: $sm) {
					width: 80%;
				}
			}
			&_img3 {
				width: 30%;
				position: absolute;
				top: -0.5%;
				right: 0;
				transform: rotate(180deg);
				@media (max-width: $xl) {
					width: 45%;
				}
				@media (max-width: $md) {
					width: 55%;
				}
				@media (max-width: $sm) {
					width: 60%;
				}
				img {
					width: 100%;
				}
			}
		}
	}
}
